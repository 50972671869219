<template>
    <div>
        <b-modal id="modal-1" title="Edit No Rekening" ok-only ok-title="Accept" size="lg" no-close-on-backdrop
            hide-footer>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col>
                        <b-form-group>
                            <label for="InputHelp">
                                Nama Bank
                            </label>
                            <validation-provider #default="{ errors }" name="bank" rules="required">
                                <b-form-input v-model="input.bank" :state="errors.length > 0 ? false:null"
                                    placeholder="bank" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <label for="InputHelp">
                                Kode Bank
                            </label>
                            <validation-provider #default="{ errors }" name="kode_bank" rules="required">
                                <b-form-input v-model="input.kode_bank" :state="errors.length > 0 ? false:null"
                                    placeholder="kode_bank" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <label for="InputHelp">
                                No Rekening
                            </label>
                            <validation-provider #default="{ errors }" name="no_rekening" rules="required">
                                <b-form-input v-model="input.no_rekening" :state="errors.length > 0 ? false:null"
                                    placeholder="no_rekening" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>



                        <b-form-group>
                            <label for="InputHelp">
                                Atas Nama
                            </label>
                            <validation-provider #default="{ errors }" name="atas_nama" rules="required">
                                <b-form-input v-model="input.atas_nama"   :state="errors.length > 0 ? false:null"
                                    placeholder="atas_nama" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>


                        <b-form-group>
                            <label for="textarea-default">Keterangan</label>
                            <b-form-textarea id="textarea-default" v-model="input.keterangan" placeholder="Textarea"
                                rows="3" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col md="6">
                        <b-button variant="primary" size="sm" class="mr-2" @click.prevent="validationForm">
                            <feather-icon icon="AlignJustifyIcon" size="15" /> Update
                        </b-button>
                        <b-button variant="warning" size="sm" @click="close()">
                            <feather-icon icon="XIcon" size="15" /> Batalkan
                        </b-button>
                    </b-col>
                </b-row>
                <br>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    // eslint-disable-next-line
    import 'quill/dist/quill.snow.css'
    // eslint-disable-next-line
    import 'quill/dist/quill.bubble.css'

    import {
        quillEditor
    } from 'vue-quill-editor'
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup,
        BFormTextarea,
        BCardText
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import {
        required
    } from '@validations'

    export default {
        components: {
            BButton,
            ValidationProvider,
            ValidationObserver,
            BForm,
            BCol,
            BModal,
            BAlert,
            quillEditor,
            BFormInput,
            BRow,
            BFormGroup,
            BFormTextarea,
            BCardText
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        data() {
            return {
                input: {},
                required,
                editorOption: {
                    modules: {
                        toolbar: '#toolbar',
                    },
                },
                content: ''
            }
        },
        emits: ['simpan'],
        methods: {
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$emit('simpan', this.input);
                        this.$root.$emit('bv::hide::modal', 'modal-1')
                        this.input = {}

                    }
                })
            },
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-1')
            }
        }
    }
</script>